// Modified version of https://github.com/twbs/bootstrap/blob/v5.2.3/js/src/util/swipe.js

/* eslint-disable no-underscore-dangle, import/no-extraneous-dependencies */

import EventHandler from 'bootstrap/js/src/dom/event-handler'
import Config from 'bootstrap/js/src/util/config'
import { execute } from 'bootstrap/js/src/util/index'

const NAME = 'swipe'
const EVENT_KEY = '.bs.swipe'
const EVENT_TOUCHSTART = `touchstart${EVENT_KEY}`
const EVENT_TOUCHMOVE = `touchmove${EVENT_KEY}`
const EVENT_TOUCHEND = `touchend${EVENT_KEY}`
const POINTER_TYPE_TOUCH = 'touch'
const POINTER_TYPE_PEN = 'pen'
const TOUCHEVENT_COMPAT_WAIT = 500 // Time for mouse compat events to fire after touch
const SWIPE_THRESHOLD = 20 // Is 40 using bootstrap's swipe.js

const Default = {
  endCallback: null,
  leftCallback: null,
  rightCallback: null
}

const DefaultType = {
  endCallback: '(function|null)',
  leftCallback: '(function|null)',
  rightCallback: '(function|null)'
}

/**
 * Class definition
 */

export class Swipe extends Config {
  constructor(element, config) {
    super()
    this._element = element

    if (!element || !Swipe.isSupported()) {
      return
    }

    this._config = this._getConfig(config)
    this._deltaX = 0
    this._supportPointerEvents = Boolean(window.PointerEvent)
    this._initEvents()
  }

  // Getters
  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get NAME() {
    return NAME
  }

  // Public
  dispose() {
    EventHandler.off(this._element, EVENT_KEY)
  }

  // Private
  _start(event) {
    if (!this._supportPointerEvents) {
      this._deltaX = event.touches[0].clientX

      return
    }

    if (this._eventIsPointerPenTouch(event)) {
      this._deltaX = event.clientX
    }
  }

  _end(event) {
    if (this._eventIsPointerPenTouch(event)) {
      this._deltaX = event.clientX - this._deltaX
    }

    this._handleSwipe()
    execute(this._config.endCallback)
  }

  _move(event) {
    this._deltaX =
      event.touches && event.touches.length > 1
        ? 0
        : event.touches[0].clientX - this._deltaX
  }

  _handleSwipe() {
    const absDeltaX = Math.abs(this._deltaX)

    if (absDeltaX <= SWIPE_THRESHOLD) {
      return
    }

    const direction = absDeltaX / this._deltaX

    this._deltaX = 0

    if (!direction) {
      return
    }

    execute(
      direction > 0 ? this._config.rightCallback : this._config.leftCallback
    )
  }

  _initEvents() {
    // Bootstrap uses other events by default, but testing has shown these to be the most reliable
    EventHandler.on(this._element, EVENT_TOUCHSTART, event =>
      this._start(event)
    )
    EventHandler.on(this._element, EVENT_TOUCHMOVE, event => this._move(event))
    EventHandler.on(this._element, EVENT_TOUCHEND, event => this._end(event))
  }

  _eventIsPointerPenTouch(event) {
    return (
      this._supportPointerEvents &&
      (event.pointerType === POINTER_TYPE_PEN ||
        event.pointerType === POINTER_TYPE_TOUCH)
    )
  }

  // Static
  static isSupported() {
    return (
      'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0
    )
  }

  static useForCarousel(carousel) {
    const endCallBack = () => {
      if (carousel._config.pause !== 'hover') {
        return
      }

      // If it's a touch-enabled device, mouseenter/leave are fired as
      // part of the mouse compatibility events on first tap - the carousel
      // would stop cycling until user tapped out of it;
      // here, we listen for touchend, explicitly pause the carousel
      // (as if it's the second time we tap on it, mouseenter compat event
      // is NOT fired) and after a timeout (to allow for mouse compatibility
      // events to fire) we explicitly restart cycling

      carousel.pause()
      if (carousel.touchTimeout) {
        clearTimeout(carousel.touchTimeout)
      }

      // eslint-disable-next-line no-param-reassign
      carousel.touchTimeout = setTimeout(
        () => carousel._maybeEnableCycle(),
        TOUCHEVENT_COMPAT_WAIT + carousel._config.interval
      )
    }

    const swipeConfig = {
      leftCallback: () => carousel._slide(carousel._directionToOrder('left')),
      rightCallback: () => carousel._slide(carousel._directionToOrder('right')),
      endCallback: endCallBack
    }

    // eslint-disable-next-line no-param-reassign
    carousel._swipeHelper = new Swipe(carousel._element, swipeConfig)
  }
}
